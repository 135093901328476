import Calculator from '../assets/calculator.png'
import Converter from '../assets/converter.png'
import Restro from '../assets/restro.sudesh.png'
import Gaming from '../assets/shop.sudesh.png'
import Pizza from '../assets/pizzeria.png'

export const ProjectList = [
  {
    name:  'Calculator App',
    image: Calculator ,
    url: '/calculator/Calculator.html',
  },
  {
    name: 'Currency Converter App' ,
    image: Converter,
    url: '/Converter/converter.html',
  },
  {
    name: 'Tour & Travels Website' ,
    image: Restro,
    url: '/Tours/index.html',
  },
  {
    name:  'E-commerce Website',
    image: Gaming,
    url: 'https://shop.sudeshbajracharya.com/',
  },
  {
    name:  'Pizza Shop Website',
    image: Pizza,
    url: 'https://restro.sudeshbajracharya.com/',
  },
];