import React from 'react'
import {useParams} from 'react-router-dom'
import { ProjectList } from '../helpers/ProjectList';
import LaunchIcon from '@mui/icons-material/Launch';
import '../styles/ProjectDisplay.css'

function ProjectDisplay() {

  const {id} = useParams();
  const project = ProjectList[id]

  return (
    <div className='project'>
      <h1>{project.name}</h1>
      <img src={project.image} alt={project.name} />
      <h3>Check it out.</h3>
      <a href={project.url} target='_blank'  rel="noopener noreferrer"><LaunchIcon className='mb-11'/></a>
    </div>
  )
}

export default ProjectDisplay