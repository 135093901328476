import React from 'react'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import FacebookIcon from '@mui/icons-material/Facebook'
import EmailIcon from '@mui/icons-material/Email'

function Footer() {
  return (
    <footer className="w-full h-[200px] bg-[#21325e] flex flex-col justify-center items-center gap-4 pt-4">
      <div className="flex space-x-6">
        <LinkedInIcon className="text-white text-[40px] cursor-pointer hover:text-blue-500" />
        <FacebookIcon className="text-white text-[40px] cursor-pointer hover:text-blue-400" />
        <EmailIcon className="text-white text-[40px] cursor-pointer hover:text-red-500" />
      </div>
      <p className="text-white text-sm">&copy; 2024 Sudeshbajracharya.com</p>
    </footer>
  );
}

export default Footer;


